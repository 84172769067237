footer {
  /* background-color: #0f3460; */
  padding: 80px 0;
  color: #000;
  box-shadow: 0 0 10px 0px rgba(9, 5, 29, 0.171) !important;
}

footer .box {
  padding: 0 20px;
}

footer h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  width: max-content;
}

footer .logo {
  color: white;
  display:flex;
  align-items:center;
  gap:5px;
  margin-bottom: 20px;
}
footer h2 {
  font-size: 20px;
}

footer .row {
  justify-content: center;
}

footer p {
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

footer h2 {
  margin-bottom: 20px;
}

footer ul {
  padding: 0;
}
footer .container {
  position: relative;
}
footer li {
  margin-bottom: 10px;
  opacity: 0.5;
}

.redes span{
  color: #000;
  padding-right: 10px;
}

.redes span .hydrated {
  font-size: 35px;
}

.phonecell {
  position: fixed;
  right: 20px;
  background: green;
  padding: 7px 15px;
  border-radius: 20px;
  z-index: 999;
  bottom: 30px;
}

.phonecell a {
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.phonecell a span:nth-child(1) {
  font-size: 20px;
}

.requestExample {
  position: fixed;
  right: 20px;
  z-index: 999;
  background: rgb(144, 113, 255);
  padding: 5px 10px;
  bottom: 110px;
  border-radius: 20px;
}

.requestExample a{
  color: #fff;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.requestExample a span {
  display: flex;
  align-items: center;
}

.requestExample a span .hydrated {
  padding-right: 5px;
}